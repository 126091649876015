import React, { useRef, useEffect } from 'react';
import MainPage from 'pages/MainPage';
import EcoCenter from 'pages/EcoCenter';
import WhatToDo from 'pages/WhatToDo';
import HowToGet from 'pages/HowToGet';

import { useArticlesDispatch, getArticles } from 'components/useArticles';
import { getVideos, useVideosDispatch } from 'components/useVideos';
import { getPlaces, usePlacesDispatch } from 'components/usePlaces';
import {
  getLocalTransport,
  getHowToGetTransport,
  useTransportDispatch,
} from 'components/useTransport';
import useOnScreen from 'hooks/useOnScreen';

import { GoTopButton } from 'components/Buttons';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import Footer from 'components/Footer';
import Admin from 'pages/Admin';
import Login from 'pages/Login';
import ArticlePage from 'pages/Article';
import useAuth from 'components/useAuth';
import { MainContainer, PageContainer, FakeHeader } from 'style';

export const AppPath = {
  ROOT: '/selo-stina',
  ECO_CENTER: '/eco-center',
  CATEGORY: '/selo-stina/category',
  GOSPODAR: '/gospodar',
  GETTING_TO_STINA: '/getting-to-stina',
  LOGIN: '/login',
  ANY: '*',
};

function App() {
  const location = useLocation();
  const topContainerRef = useRef(null);
  const fakeHeaderRef = useRef(null);
  const { currentUser } = useAuth();

  const articleDispatch = useArticlesDispatch();
  const videosDispatch = useVideosDispatch();
  const placesDispatch = usePlacesDispatch();
  const transportDispatch = useTransportDispatch();

  const visibleHeader = useOnScreen(fakeHeaderRef.current, {}, true);

  useEffect(() => {
    getVideos(videosDispatch);
    getPlaces(placesDispatch);
    getArticles(articleDispatch);
    getLocalTransport(transportDispatch);
    getHowToGetTransport(transportDispatch);
    if (window.fbq) {
      window.fbq('track', 'PageView', {
        page_path: location.pathname,
      });

      const eventName =
        location.pathname === '/' ? '/selo-stina' : location.pathname;

      window.fbq('track', eventName, {
        page_path: location.pathname,
      });
    }
  }, [location]);

  return (
    <MainContainer ref={topContainerRef}>
      <FakeHeader ref={fakeHeaderRef} />
      <Router>
        <PageContainer width={window.screen.width}>
          <Switch>
            <Route path={AppPath.ECO_CENTER} component={EcoCenter} />
            <Route
              path={`${AppPath.CATEGORY}/:id`}
              component={WhatToDo}
              exact
            />
            <Route
              path={`${AppPath.CATEGORY}/:categoryId/articles/:slug`}
              component={ArticlePage}
            />
            <Route
              path={`${AppPath.CATEGORY}/:categoryId/articles/:articleId/:slug`}
              component={ArticlePage}
            />
            <Route path={AppPath.GETTING_TO_STINA} component={HowToGet} />
            <Route path={AppPath.GOSPODAR}>
              {currentUser ? <Admin /> : <Redirect to={AppPath.LOGIN} />}
            </Route>
            <Route path={AppPath.LOGIN} component={Login} />
            <Route path={AppPath.ROOT} exact>
              <MainPage
                topContainerRef={topContainerRef}
                isScrolling={!visibleHeader}
              />
            </Route>
            <Route path={AppPath.ANY}>
              <Redirect to={AppPath.ROOT} />
            </Route>
          </Switch>
          <Footer />
          {!visibleHeader && (
            <GoTopButton
              onClick={() =>
                topContainerRef.current.scrollIntoView({ behavior: 'smooth' })
              }
            >
              <KeyboardArrowUpIcon fontSize="large" />
            </GoTopButton>
          )}
        </PageContainer>
      </Router>
    </MainContainer>
  );
}

export default App;
