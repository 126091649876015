import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import footerLogo1 from 'assets/footerLogo1.png';
import footerLogo3 from 'assets/footerLogo3.png';
import { HeadingH6, Subtitle2 } from 'components/Typography';
import useTranslation from 'hooks/useTranslation';
import { mainPage } from 'strings/mainPage';
import {
  useContacts,
  getHashtags,
  getFooterContacts,
} from 'components/useContacts';
import {
  FooterContainer,
  LinksContainer,
  ContactsContainer,
  LogosContainer,
  StyledLink,
  StyledContact,
  StyledLogo1,
  StyledLogo3,
  LeftContainer,
  CenterContainer,
  RightContainer,
} from './style';

function Footer() {
  const t = useTranslation();
  const strings = t(mainPage);

  const [ContactState, ContactDispatch] = useContacts();

  useEffect(() => {
    getHashtags(ContactDispatch);
    getFooterContacts(ContactDispatch);
  }, []);

  useEffect(() => {
    const handleClick = (event) => {
      const link = event.target.closest('a');
      if (link && link.dataset.track === 'hashtag') {
        if (window.fbq) {
          window.fbq('trackCustom', 'Теги', {
            content_name: link.textContent.replace('#', ''),
            content_category: 'Hashtag',
            page_path: link.href,
          });
          window.fbq('trackCustom', `Теги:${link.textContent}`, {
            content_name: link.textContent.replace('#', ''),
            content_category: 'Hashtag',
            page_path: link.href,
          });
        }
      }
    };

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const sortedContacts = ContactState.footerContacts.sort((a, b) => {
    if (a.name.includes('@') && !b.name.includes('@')) {
      return -1;
    }
    if (!a.name.includes('@') && b.name.includes('@')) {
      return 1;
    }
    return 0;
  });

  return (
    <FooterContainer>
      <LeftContainer>
        <LinksContainer>
          {ContactState.hashtags.map((hashtag) => (
            <StyledLink
              href={hashtag.url}
              target="_blank"
              key={hashtag.name}
              data-track="hashtag"
            >
              <HeadingH6>#{hashtag.name}</HeadingH6>
            </StyledLink>
          ))}
        </LinksContainer>
      </LeftContainer>
      <CenterContainer>
        <ContactsContainer>
          {sortedContacts.map((contact) => (
            <StyledContact key={contact.name}>
              <HeadingH6>{contact.name}</HeadingH6>
            </StyledContact>
          ))}
        </ContactsContainer>
      </CenterContainer>
      <RightContainer>
        <LogosContainer>
          <StyledLogo1 src={footerLogo1} />
          <StyledLogo3 src={footerLogo3} />
        </LogosContainer>
        <Box color="white">
          <Subtitle2>
            &copy;
            {` ${strings.copyright}. ${new Date(Date.now()).getFullYear()}`}
          </Subtitle2>
        </Box>
      </RightContainer>
    </FooterContainer>
  );
}

export default Footer;
